// Please write exact color name
:root {
  --sidebar-color: #1c2434;

  --bg-color-primary: #f8f9fe;
  --bg-color-white: #ffffff;
  --bg-color-secondary: #f9fdff;
  --text-color-info: #0094da;
  --text-color-primary: #202020;
  --text-label-color: #4e5b76;
  --text-color-secondary: #464e5f;
  --sidebar-width: 250px;
}

/////////////////////////////////////////////////////////////////
///////////// Follow the same color name below//////////////////
///////////////////////////////////////////////////////////////

// These variables with $ sign will be used in all other style file
// No color code will be write in any style file directly

//Primary Background and text colors
$sidebar-color: var(--sidebar-color);
$primary-color: var(--text-color-info);
$white-color: var(--bg-color-white);

$bg-color-primary: var(--bg-color-primary);
$bg-color-secondary: var(--bg-color-secondary);

$text-color-primary: var(--text-color-primary);
$text-label-color: var(--text-label-color);
$text-color-secondary: var(--text-color-secondary);

$border-radius: 6px;
$transitionDuration: 0.5s;
$gutter: 5px;
$sidebar-width: var(--sidebar-width);
$topbar-height: 4rem;
