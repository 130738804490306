.permission-list {
  .accordion-item {
    margin-bottom: 15px;
    .p-panel-header {
      margin-bottom: 10px;
      .p-panel-header-icon {
        min-width: auto;
      }
    }
    .p-panel-content,
    .p-panel-header {
      border-radius: 5px;
      border: 0;
      background-color: #fff;
    }
  }
}
.accordion-role-list {
  a {
    flex-direction: row-reverse;
    gap: 10px;
  }
}
.d-grid {
  display: grid;
}
