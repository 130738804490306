.input-layout {
  label div {
    font-family: Poppins;
    color: $text-label-color !important;
    font-size: 14px;
    font-weight: 600;
  }
  .custom-dropdown,
  input {
    border-color: #ecedef !important;
    font-size: 14px;
    font-family: Poppins;
  }

  .numeric_input{
    padding: 10px;
  }
  .custom-dropdown,
  input:focus {
    box-shadow: none;
    border-color: #c8c9ca;
    box-shadow: none;
    outline: none;
  }
  .custom-dropdown,
  input::placeholder {
    color: #cbcbcb;
    opacity: 1;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
  }

  .custom-dropdown,
  input::-ms-input-placeholder {
    color: #cbcbcb;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
  }
  .custom-dropdown span.p-dropdown-label.p-inputtext.p-placeholder {
    color: #cbcbcb;
  }
}
.chips {
  ul {
    width: 100%;
  }
}
.phone-input {
  border: 1px solid #ecedef;
  border-radius: 7px;
  overflow: hidden;
  padding: 5px 10px;
  input {
    padding: 10px;
    border: none;
    &:focus {
      outline: none;
    }
  }
}
