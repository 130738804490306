.p-button:focus {
  box-shadow: none;
}
.p-overlaypanel {
  background-color: $bg-color-secondary;
}
.p-overlaypanel-content {
  padding: 5px;
}
.p-calendar {
  background-color: $bg-color-secondary !important;
}
