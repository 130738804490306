.layout-main-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
  // padding: $topbar-height + 1.5rem 1.5rem 1.5rem 1.5rem;
  padding-top: $topbar-height;
  transition: margin-left $transitionDuration;
}

.layout-main {
  min-height: calc(100vh  - $topbar-height);
  overflow-y: auto;
  padding: 1.5rem;
}
.auth-content{
  background: rgba(0,0,0,0.5);
}
.container {
  max-width: 1200px; /* Example maximum width */
  margin: 0 auto; /* Center align the container */
  padding: 0 20px; /* Optional padding */

  /* Optional: Add responsive styles using media queries */
  @media (max-width: 768px) {
    max-width: 100%; /* Fluid width on smaller screens */
    padding: 0 10px; /* Adjust padding for smaller screens */
  }
}