.auth-page-layout {
  width: 100%;
  height: 100vh;

  .logo {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
    margin-bottom: 30px;
    div {
      color: $text-color-primary;
      margin: auto 0;
      font-size: 32px;
      font-weight: 600;
    }
    svg {
      margin: auto 0;
      height: 100%;
    }
  }

  .side-img-div {
    object-fit: contain;
    height: 100vh;
    background-position: center;
    background-size: cover;
    position: relative;
    overflow: hidden;

    .side-img-text {
      width: 80%;
      padding: 30px 25px;
      // opacity: 0px;
      color: #ffffff;
      position: absolute;
      bottom: 8%;
      right: 8%;

      backdrop-filter: blur(14px) saturate(163%);
      -webkit-backdrop-filter: blur(14px) saturate(163%);
      background-color: rgba(42, 42, 42, 0.59);
      border-radius: 12px;

      font-family: Poppins;
      font-size: 16px;
      font-weight: 400;
      line-height: 31.7px;
      letter-spacing: 0.3850000202655792px;
    }
  }

  .login-img {
    background-image: url("../../images/LoginImg.png");
  }
  .signup-img {
    background-image: url("../../images/SignupImg.png");
  }

  .create-project-img {
    background-image: url("../../images/dashboard-bg.jpg");
  }

  .form-title {
    font-family: Poppins;

    font-weight: 600;
    color: $text-color-primary;
  }

  .line {
    width: 120px;
    height: 2px;
    background-color: $primary-color;
    margin-bottom: 20px;
  }

  .auth-form {
    max-height: 90vh;
    overflow-y: auto;
  }
  .terms {
    color: #8e8e8e !important;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
  }
}

@media (min-width: 992px) {
  .auth-form {
    padding-left: 10%;
    padding-right: 15%;
  }
  .form-title {
    font-size: 46px;
  }
}

@media (max-width: 991px) {
  .auth-page-layout {
    position: relative;
  }
  .auth-form {
    padding-left: 0%;
    padding-right: 0%;
    margin: auto;
    padding: 20px;
    border-radius: $border-radius;
    top: 10%;
    position: absolute;
    background-color: $bg-color-primary;
  }
  .form-title {
    font-size: 30px;
  }
}
