.layout-sidebar {
  position: fixed;
  width: $sidebar-width;
  height: 100vh;
  z-index: 9;
  overflow-y: auto;
  user-select: none;
  top: 0;
  left: 0;
  transition: transform $transitionDuration, left $transitionDuration;
  background-color: $sidebar-color;

  color: #939393;

  .logo {
    height: $topbar-height;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
    padding: 0 35px;
    div {
      color: #ffffff;
      margin: auto 0;
      font-size: 32px;
      font-weight: 600;
    }
    svg {
      margin: auto 0;
      height: 100%;
    }
  }

  .menu-bar {
    height: calc(100% - $topbar-height);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
  }

  .sidebar-header {
    overflow-y: auto;
  }
  .open-icon {
    transition: $transitionDuration;
  }
  .open {
    transform: rotate(-90deg);
  }

  // .......
  .sidebar-item {
    margin: 15px 0;
    width: 100%;
    border-radius: $border-radius;
    padding: 10px;
    transition: $transitionDuration;
    color: #ffffff;
    div {
      color: #ffffff;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }
    svg {
      height: 25px;
      width: 25px;
    }
    &.active {
      background-color: #333a48;
    }
  }

  .sidebar-item:hover {
    background-color: #333a48;
  }
  .sidebar-item:first-child {
    margin: 0 0;
  }
  .menu-items {
    padding-left: 38px;
    padding-right: 10px;
    transition: $transitionDuration;
    cursor: pointer;
    margin: 5px 0;
    div {
      color: #ffffff;
      font-size: 14px;
      font-weight: 600;
      margin: 15px 0;
      &.active {
        color: $primary-color;
      }
    }
  }
  .menu-items div:first-child {
    margin: 0;
  }
  .menu-links {
    padding-left: 10px;
    padding-right: 10px;
    transition: $transitionDuration;
    cursor: pointer;
    div {
      font-size: 14px;
      font-weight: 400;
      color: #909090;
      margin: 15px 0;
    }
    & .active {
      color: $primary-color;
    }
  }
}
