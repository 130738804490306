.filter-overlay-panel {
  .p-overlaypanel-content {
    padding: 0;

    h4 {
      background: var(--bg-color-primary);
      padding: 15px 5px !important;
    }

    ul {
      height: 300px;
      overflow: auto;
      margin-top: 0;

    }
    .primary-button{
      margin-bottom: 10px;
    }
  }
}

.view-enery-meter-sidebar {
  width: 50%;
}

@media screen and (max-width: 991px) {
  .view-enery-meter-sidebar {
    width: 70%;
  }
}

@media screen and (max-width: 576px) {
  .view-enery-meter-sidebar {
    width: 100%;
  }
}

.table-list {
  tr.bg-primary-color {
    td {
      color: #fff;
    }
  }
}