.login {
  min-width: 100%;
  min-height: 100vh;

  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.026;
  }
  .login-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 3;
  }
  .form {
    min-height: 200px;
  }
}

.loginV2 {
  min-width: 100%;
  height: 100vh;

  overflow: hidden;

  .background {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
  }
  .login-form {
    z-index: 2;

    backdrop-filter: blur(10px) saturate(200%);
    -webkit-backdrop-filter: blur(10px) saturate(200%);
    background-color: rgba(101, 123, 126, 0.8);
    border-radius: 12px;
    border: 1px solid rgba(209, 213, 219, 0.3);
    padding: 10px;
  }
  .form {
    margin: auto;
    text-align: left;
    width: 84%;

    label {
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 16.94px;
      cursor: pointer;
    }

    input {
      width: 100%;
      height: 46px;
      border-radius: 12px;
      border: 0.4px 0px 0px 0px;
      color: #ffffff;
      margin-top: 5px;
      padding-left: 10px;

      background: #ffffff1a;
      border: 0.4px solid #ffffff;
      box-shadow: -42.3px 42.3px 42.3px 0px #ffffff1a inset;
      box-shadow: 42.3px -42.3px 42.3px 0px #c2c2c21a inset;
    }
    input:focus {
      outline: none;
    }

    button {
      width: 100%;
      height: 46px;
      padding: 11.11px 8.54px 10.25px 8.54px;
      gap: 11.11px;
      border-radius: 12px;
      background: #ffffff;
      box-shadow: 0px 4px 33px 0px #3c3c3c30;
      border: none;
      color: #2f2f2f;

      font-size: 16px;
      font-weight: 600;
      line-height: 19.36px;
      cursor: pointer;
    }
  }
}
