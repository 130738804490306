th {
  background-color: transparent !important;
}
tr {
  background-color: transparent !important;
}

.financial-table{
  tr{
    th{
      background: $bg-color-primary !important;
      padding: 13px;
      text-align: start;
    }
    td{
      padding: 5px;
    }
  }
}