* {
  scrollbar-width: thin;
  // scrollbar-color: $bg-color-primary $bg-color-secondary;
  scrollbar-color: $bg-color-primary;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: $bg-color-secondary;
}

*::-webkit-scrollbar-thumb {
  background-color: $bg-color-primary;
  border-radius: 20px;
}

.scrollable-card {
  scrollbar-width: thin;
  scrollbar-color: $bg-color-secondary $bg-color-primary;
}

/* Works on Chrome, Edge, and Safari */
.scrollable-card::-webkit-scrollbar {
  width: 8px;
}

.scrollable-card::-webkit-scrollbar-track {
  background: $bg-color-primary;
}

.scrollable-card::-webkit-scrollbar-thumb {
  background-color: $bg-color-secondary;
  border-radius: 20px;
}
