.card {
  background-color: $white-color;
  color: $text-color-secondary;
  border-radius: $border-radius;
  padding: 14px 16px;
  box-shadow: 0px 3.75px 27.17px 0px #e7e7e740;

  overflow-x: auto;

  .title {
    font-size: 22px;
    font-weight: 600;
    color: $primary-color;
  }
}
.sidebar-title {
  font-size: 22px;
  font-weight: 600;
  color: $primary-color;
}

.scrollable-card {
  height: 220px;
  overflow-y: auto;
}

.image-container img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.greenValue {
  font-family: Inter;
  font-size: 17px;
  font-weight: 600;
  color: #0cb954;
}
.yellowUnit {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  color: #de9d1e;
}
