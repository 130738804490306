.p-tabview {
  &.tabs-design {
    margin-top: 10px;
    ul {
      border: none;
      li {
        width: 20%;
        border: 1px solid #c9c9c9;
        a {
          color: #747474;
          font-size: 16px;
          font-weight: 400;
        }
        &.p-tabview-selected.p-highlight {
          border-top: 2px solid #1a1818;
          a {
            color: #202020;
            border: none;
            font-weight: 600;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 991px) {
  .p-tabview {
    &.tabs-design {
      ul {
        li {
          width: auto;
        }
      }
    }
  }
}
