.p-dialog-footer {
  background: none;
}

.search-box {
  position: relative;
  input {
    background-color: $bg-color-primary;
    border: $bg-color-primary;
  }

  i {
    position: absolute;
    right: 13px;
    top: 10px;
    cursor: pointer;
  }
}

.input-box {
  position: relative;
  i {
    position: absolute;
    right: 13px;
    top: 13px;
    cursor: pointer;
  }
}

.text-primary {
  color: $primary-color;
}
.calendar-box {
  position: relative;
  i {
    position: absolute;
    right: 13px;
    top: 13px;
    cursor: pointer;
  }
}
.primary-button {
  background-color: $primary-color;
  min-width: 140px;
  border-color: $primary-color;
}
.primary-button-outlined {
  color: $primary-color;
  min-width: 140px;
  border-color: $primary-color;
}

.p-password {
  width: 100%;
  div {
    width: 100%;
  }
  input {
    width: 100%;
  }
}

.p-inputswitch.p-highlight .p-inputswitch-slider {
  background-color: $primary-color;
}
.p-inputswitch {
  flex-shrink: 0;
}
.link {
  color: $primary-color;
  text-decoration: none;
}
.p-checkbox.p-highlight .p-checkbox-box {
  border-color: $primary-color;
  background-color: $primary-color;
}

.bg-primary-color {
  background-color: $primary-color !important;
}

.bg-grey-color {
  background-color: #edf2fa !important;
}

.clear-button-outlined {
  color: $primary-color;
  border-color: $primary-color;
  background-color: #fff;
}
