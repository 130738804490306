/* select role */
.profile-role {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #D3D3D3;
    border-radius: 50%;
    width: 180px;
    height: 180px;
}

.active-role {
    border-color: #0094DA;
}

.invitation-modal {
    .p-dialog-header {
        background: $primary-color;
        padding: 10px;

        .p-dialog-title {
            color: #fff;
            text-align: center;
        }

        .p-dialog-header-icon {
            min-width: auto;
            color: #fff;
        }
    }

    .p-dialog-content {
        padding: 20px;
    }
}

.firstLetterCapital::first-letter {
    text-transform: uppercase;
}