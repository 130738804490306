.alarm {
  .dateButton {
    height: 34px;
    padding: 6px 10px 6px 10px;
    gap: 10px;
    border-radius: 4px;
    border: 1px solid #ccc7c7;
  }

  .p-datepicker {
    color: $bg-color-secondary !important;
    color: #4b5563 !important;
  }
  .indicator {
    height: 15px;
    width: 15px;
  }
}
.p-datepicker {
  div {
    color: #4b5563;
  }
}
