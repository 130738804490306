.news {
  .news-img-small {
    max-width: 120px;
    max-height: 120px;
    object-fit: contain;
    border-radius: $border-radius;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
