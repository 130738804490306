h1,
h2,
h3,
h4,
h5,
h6,
label {
  color: $text-color-primary;
  margin: 0;
  padding: 0;
}
p {
  color: $text-color-secondary;
  margin: 0;
  padding: 0;
}
div {
  color: $text-color-primary;
}

.bg-primary-light{
  background: $bg-color-primary;
}