.page {
  .page-title {
    font-family: Gadugi;
    font-size: 24px;
    font-weight: 700;
  }
  .back-icon {
    width: 30px;
    cursor: pointer;
  }
  .page-description {
    margin-left: 30px;
  }
  .page-description {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
  }
}
