@import url("https://unpkg.com/primeflex@latest/primeflex.css");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import "./assets/styles/all.scss";

body {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $bg-color-secondary;
}

hr {
  border: none;
  border-top: 1px solid $bg-color-secondary;
}

.border-bottom {
  border-bottom: 1px solid $bg-color-secondary;
}

.p-dialog {
  background-color: $bg-color-primary;
}
.search-box {
  .p-inputtext {
    padding: 8.5px 20px;
  }
  .p-inputtext:focus {
    box-shadow: none;
  }
}
.p-dialog-header,
.p-dialog-content {
  background: none;
}

//create project css start

.wrapper-auth {
  min-height: 100vh;
}

.bg-lightest {
  background-color: #f8f9fe;
}

.bg-lightest-main {
  background-color: #f2fafd;
}
.calendar-box {
  span {
    width: 100%;
  }
}
.p-datepicker {
  z-index: 1;
}
.step-indicator {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  margin: 0 5px;
  background-color: #fff;
  color: #000;
  border: 1px solid #0094da;
  border-radius: 50%;
  text-align: center;
  position: relative;
  z-index: 1;
}

.step-indicator::before {
  content: "";
  position: absolute;
  width: 68px;
  z-index: 0;
  height: 0px;
  border: 1px dashed #0094da;
  top: 48%;
  left: 30px;
}

.step-indicator:nth-last-child(1)::before {
  display: none;
}

.indicators-step {
  gap: 60px;
}

.step-indicator.active {
  background-color: #0094da;
  color: #fff;
}

//create project css end

//Make first letter capital
.firstletter_capital {
  text-transform: capitalize;
}

//Make first letter capital
.lowercase-text {
  text-transform: lowercase;
}

.card-col-12 {
  padding: 0rem !important;
}

.custom-header {
  background-color: var(--bg-color-primary) !important;
  color: white;
  /* Ensure the text is readable on the background */
}

.image-view {
  width: 99px;
  height: 99px;
  border-radius: 5px;
  object-fit: cover;
}

.table-list {
  .p-frozen-column {
    background: #fff;
  }
  .p-selectable-row.bg-grey-color {
    .p-frozen-column {
      // background: var(--text-color-info) !important;
      background: #edf2fa !important;
    }
  }
  .p-column-header-content .p-column-filter {
    margin: 0;
  }
}

.tooltip {
  div {
    background-color: #fff;
  }
}

.custom-rating .p-rating-icon {
  color: gold; /* Change to your desired color */
}

.custom-rating .p-rating-icon.p-highlight {
  color: orange; /* Change to highlight color */
}
.flex-shrink-0 {
  flex-shrink: 0;
}
.add-document {
  margin-right: -8px;
}
.calendar-box {
  input {
    padding-right: 35px;
  }
}
.overflow-auto {
  width: 100%;
}
.date-filter {
  .p-column-filter-overlay {
    left: 19% !important;
  }
  .p-datepicker {
    left: 40% !important;
  }
}
.add-comment {
  padding: 14px !important;
}
.three-dot {
  right: -64px;
  transform: translateY(-50%);
}
.justify-content-end.justify-content-end {
  justify-content: end !important;
}
// form{
//   .p-error {
//     position: absolute;
//     top: 100%;
// }
// }
// @media (min-width:991px){
//   .p-column-filter-overlay {
//     left: 19% !important;
//   }
//   .p-datepicker {
//     left: 40% !important;
//   }
// }

.fixed-overlay-panel {
  position: fixed !important;
  top: 50px !important;
  right: 20px !important;
  left: auto !important;
  display: block !important;
}

.table-list .bg-grey-color .p-frozen-column {
  background: #edf2fa !important;
}